<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">
          Elective Care Recovery (ECR)
        </h1>
      </div>

      <div class="validation-cards--item cert no-hover">
        <span class="card-header">Certification Status</span>
        <div class="card-icon" margin-top="0px">
          <img
            src="@/assets/img/circular-label-with-certified-stamp.png"
            alt=""
          />
          <span class="card-total">100% Validation</span>
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
    </div>

    <nav class="route-nav">
      <ul>
        <li
          @click="editStageSummary('ecrinpatient')"
          :class="stageSummary == 'ecrinpatient' ? 'active' : ''"
        >
          <span>ECR<br>Inpatient Summary</span>
        </li>
        <li
          @click="editStageSummary('specialityecr')"
          :class="stageSummary == 'specialityecr' ? 'active' : ''"
        >
          <span>Speciality ECR</span>
        </li>
        <li
          @click="editStageSummary('ecropd')"
          :class="stageSummary == 'ecropd' ? 'active' : ''"
        >
          <span>ECR OPD</span>
        </li>
        <li
          @click="editStageSummary('impatientECR')"
          :class="stageSummary == 'impatientECR' ? 'active' : ''"
        >
          <span>Inpatient ECR</span>
        </li>
        <li
          @click="editStageSummary('gap')"
          :class="stageSummary == 'gap' ? 'active' : ''"
        >
          <span>Gap Analysis</span>
        </li>
      </ul>
    </nav>


    


    <div v-if="stageSummary == 'ecropd'">
      <div class="route-content">
          <div class="new_new_stat-cards">
             <div class="new_new_stat-cards--item jessica no-hover" style="width:100%; height: 80px;">
              <span class="card-header">Important</span>           
              <span class="card-total3">The table(s) below have a horizontal scroll bar at the bottom due to the amount of data displayed</span>
            </div>

          <div class="search-table-outter wrapper">
            <table class="search-table inner">
               <thead>
                <tr style="background: rgba(229, 233, 242, .5);">
                  <th colspan="22" class="border px-4 py-2">Specialty  |  OPD Elective Care Recovery (ECR)</th>
                  <th class="border px-4 py-2">Balanced Waiting List </th>
                </tr>
              </thead>
            
              <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Specialty OPD</td>
      <td class="border" style="padding: 10px;">Week 1</td>
      <td class="border" style="padding: 10px;">Week 2</td>
      <td class="border" style="padding: 10px;">Week 3</td>
      <td class="border" style="padding: 10px;">Week 4</td>
      <td class="border" style="padding: 10px;">Week 5</td>
      <td class="border" style="padding: 10px;">Week 6</td>
      <td class="border" style="padding: 10px;">Week 7</td>
      <td class="border" style="padding: 10px;">Week 8</td>
      <td class="border" style="padding: 10px;">Week 9</td>
      <td class="border" style="padding: 10px;">Week 10</td>
      <td class="border" style="padding: 10px;">Week 11</td>
      <td class="border" style="padding: 10px;">Week 12</td>
      <td class="border" style="padding: 10px;">Week 13</td>
      <td class="border" style="padding: 10px;">Week 14</td>
      <td class="border" style="padding: 10px;">Week 15</td>
      <td class="border" style="padding: 10px;">Week 16</td>
      <td class="border" style="padding: 10px;">Week 17</td>
      <td class="border" style="padding: 10px;">Week 18</td>
      <td class="border" style="padding: 10px;">Week 19</td>
      <td class="border" style="padding: 10px;">Week 20</td>
      <td class="border" style="padding: 10px;">Week 21</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">DEMAND</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Contract Plan</td>
      <td class="border" style="padding: 10px;">Wk 1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">70.56</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">70.56</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">BACKLOG CLEARANCE required </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Demand above Regular Referral</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity Target </td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Core capacity first OP</td>
      <td class="border" style="padding: 10px;">Wk 1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">Total </td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">11025</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">11025</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">current capacity gap </td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">98.1151789905412</td>
      <td class="border" style="padding: 10px;">2060.41875880136</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Plan to bridge gap </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Demand Management - increase cases per Clinic </td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">420</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Virtual Follow-Up Clinics</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">850</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional Clinic Sessions (6 Days per week)</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">357</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">20</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">91</td>
      <td class="border" style="padding: 10px;">1627</td>
   </tr>
  </tbody>
 </table>
          </div>

         

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
 <thead>
  <tr  style="background: rgba(229, 233, 242, .5);">
    <th colspan="22" class="border px-4 py-2">ACTUAL ACTIVITY - complete weekly </th>
    <th class="border px-4 py-2">Balanced Waiting List </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity with core capacity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional Internal capacity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity Outsourced</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Capacity lost </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Bank Holidays </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Annual Leave </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Study Leave </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">On call </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Sickness </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Staffing issue/ vacancy </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">unbooked slots </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total lost capacity </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">% LOST CAPACITY </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total activity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Volume</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total activity </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total outsourced</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Specialty Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Variance to Plan </td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;">-623.115178990541</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Current Backlog Balance</td>
      <td class="border" style="padding: 10px;">235</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">70.56</td>
   </tr>
  </tbody>
 </table>
</div>

          
          </div>        
      </div>   
    </div> 
        
    <div v-if="stageSummary == 'specialityecr'">
      <div class="route-content">
          <div class="new_new_stat-cards">
             <div class="new_new_stat-cards--item jessica no-hover" style="width:100%; height: 80px;">
              <span class="card-header">Important</span>           
              <span class="card-total3">The table(s) below have a horizontal scroll bar at the bottom due to the amount of data displayed</span>
            </div>

            <div class="search-table-outter wrapper">
          <table class="search-table inner">
 <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="19" class="border px-4 py-2">Specialty  |  FIRST OPD BACKLOG CLEARANCE PTL</th>
    <th class="border px-4 py-2">Balanced Waiting List (IST)</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Specialty OPD</td>
      <td class="border" style="padding: 10px;">Week 1</td>
      <td class="border" style="padding: 10px;">Week 2</td>
      <td class="border" style="padding: 10px;">Week 3</td>
      <td class="border" style="padding: 10px;">Week 4</td>
      <td class="border" style="padding: 10px;">Week 5</td>
      <td class="border" style="padding: 10px;">Week 6</td>
      <td class="border" style="padding: 10px;">Week 7</td>
      <td class="border" style="padding: 10px;">Week 8</td>
      <td class="border" style="padding: 10px;">Week 9</td>
      <td class="border" style="padding: 10px;">Week 10</td>
      <td class="border" style="padding: 10px;">Week 11</td>
      <td class="border" style="padding: 10px;">Week 12</td>
      <td class="border" style="padding: 10px;">Week 13</td>
      <td class="border" style="padding: 10px;">Week 14</td>
      <td class="border" style="padding: 10px;">Week 15</td>
      <td class="border" style="padding: 10px;">Week 16</td>
      <td class="border" style="padding: 10px;">Week 17</td>
      <td class="border" style="padding: 10px;">Week 18</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td colspan="19" class="border" style="padding: 10px;">DEMAND </td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Regular Referral Inflow</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;">408.600117370847</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">backlog clearance </td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;">214.515061619695</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total demand</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;">623.115178990541</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td colspan="19" class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td colspan="19" class="border" style="padding: 10px;">ACTIVITY </td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity with core capacity </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional Internal Capacity</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity (outsourcing)</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total actvity (slots)</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total lost capacity </td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;">525</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">% lost capacity</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Backlog Balance</td>
      <td class="border" style="padding: 10px;">235</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>
</div>

<div class="search-table-outter wrapper">
 <table class="search-table inner">
<thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="19" class="border px-4 py-2"> Admitted PTL backlog clearance </th>
    <th class="border px-4 py-2">Balanced Waiting List (IST)</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Specialty Elective</td>
      <td class="border" style="padding: 10px;">Week 1</td>
      <td class="border" style="padding: 10px;">Week 2</td>
      <td class="border" style="padding: 10px;">Week 3</td>
      <td class="border" style="padding: 10px;">Week 4</td>
      <td class="border" style="padding: 10px;">Week 5</td>
      <td class="border" style="padding: 10px;">Week 6</td>
      <td class="border" style="padding: 10px;">Week 7</td>
      <td class="border" style="padding: 10px;">Week 8</td>
      <td class="border" style="padding: 10px;">Week 9</td>
      <td class="border" style="padding: 10px;">Week 10</td>
      <td class="border" style="padding: 10px;">Week 11</td>
      <td class="border" style="padding: 10px;">Week 12</td>
      <td class="border" style="padding: 10px;">Week 13</td>
      <td class="border" style="padding: 10px;">Week 14</td>
      <td class="border" style="padding: 10px;">Week 15</td>
      <td class="border" style="padding: 10px;">Week 16</td>
      <td class="border" style="padding: 10px;">Week 17</td>
      <td class="border" style="padding: 10px;">Week 18</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">DEMAND</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Regular Referral Inflow PLAN </td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">backlog clearance </td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total demand</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;">72.010017605627</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ACTIVITY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity with core capacity </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity (WLI)</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity (outsourcing)</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total capacity (slots)</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total lost capacity </td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">% waste capacity</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Backlog Waiting List balance</td>
      <td class="border" style="padding: 10px;">536</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>
</div>

          
     </div>        
      </div>   
    </div>       
    
    <div v-if="stageSummary == 'ecrinpatient'">
      <div class="route-content">
          <div class="new_new_stat-cards">
             <div class="new_new_stat-cards--item jessica no-hover" style="width:100%; height: 80px;">
              <span class="card-header">Important</span>           
              <span class="card-total3">The table(s) below have a horizontal scroll bar at the bottom due to the amount of data displayed</span>
            </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
          <thead>
            <tr style="background: rgba(229, 233, 242, .5);">
              <th class="border px-4 py-2">DAY SURGERY</th>
              <th class="border px-4 py-2">Outturn Activity</th>
              <th class="border px-4 py-2">Backlog Activity Demand</th>
              <th class="border px-4 py-2">Additional Activity ECRB (Conversion, say 2% of all specialties)</th>
              <th class="border px-4 py-2">Total Activity</th>
              <th class="border px-4 py-2">Session Capacity Required</th>
              <th class="border px-4 py-2">Annual DSU Theatre Session Capacity Available (5 Days)</th>
              <th class="border px-4 py-2">Annual DSU Session Capacity Available (5 Days, 50 Weeks)</th>
              <th class="border px-4 py-2">Annual DSU Session Capacity Available (5 Days, 48 Weeks)</th>
              <th class="border px-4 py-2">Annual DSU Session Capacity Available (5 Days, 46 Weeks)</th>
              <th class="border px-4 py-2">Annual DSU Session Capacity Available (5 Days, 44 Weeks)</th>
              <th class="border px-4 py-2">Capacity Gap/Surplus</th>
              <th class="border px-4 py-2">Anaesthetic Capacity (Sessions Per Week)</th>
              <th class="border px-4 py-2">Anaesthetic Capacity (42 Weeks)</th>
              <th class="border px-4 py-2">Anaesthetic Demand DSU (70% of Total Caseload)</th>
              <th class="border px-4 py-2">Capacity Gap/Surplus</th>
              <th class="border px-4 py-2">Surgical PAs</th>
              <th class="border px-4 py-2">Capacity Gap/Surplus</th>
              <th class="border px-4 py-2">Weekly Patient Volume Required (48 Weeks)</th>
              <th class="border px-4 py-2">Daily Patient Volume (48 Weeks)</th>
              <th class="border px-4 py-2">Weekly Sessions Required</th>
              <th class="border px-4 py-2">Daily Sessions Required</th>
              <th class="border px-4 py-2">Location</th>
              <th class="border px-4 py-2">Commentary</th>
            </tr>
          </thead>
          <tbody>
              <tr style="height: 0; padding: 10px;">
                <td class="border" style="padding: 10px;">Cardiac Surgery</td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
                <td class="border" style="padding: 10px;"></td>
            </tr>
            <tr style="height: 0; padding: 10px;">
              <td class="border" style="padding: 10px;">Dental</td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
              <td class="border" style="padding: 10px;"></td>
          </tr>
          <tr style="height: 0; padding: 10px;">
            <td class="border" style="padding: 10px;">ENT</td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
        </tr>
          <tr style="height: 0; padding: 10px;">
            <td class="border" style="padding: 10px;">General Surgery</td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
            <td class="border" style="padding: 10px;"></td>
        </tr>
        <tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;">Maxillofacial</td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
      </tr>
        <tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;">Neuro Surgery</td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
      </tr>
        <tr style="height: 0; padding: 10px;">
          <td class="border" style="padding: 10px;">Paediatric Surgery</td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
          <td class="border" style="padding: 10px;"></td>
      </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Plastic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Thoracic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Trauma & Orthopaedics</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Urology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Gynaecology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Vascular Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">TOTAL</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">3900</td>
      <td class="border" style="padding: 10px;">3750</td>
      <td class="border" style="padding: 10px;">3600</td>
      <td class="border" style="padding: 10px;">3450</td>
      <td class="border" style="padding: 10px;">3150</td>
      <td class="border" style="padding: 10px;">3150</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">Total Patient Capacity</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">18018</td>
      <td class="border" style="padding: 10px;">17325</td>
      <td class="border" style="padding: 10px;">16632</td>
      <td class="border" style="padding: 10px;">15939</td>
      <td class="border" style="padding: 10px;">15246</td>
      <td class="border" style="padding: 10px;">15246</td>
      <td class="border" style="padding: 10px;">37.247</td>
      <td class="border" style="padding: 10px;">1564.374</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">1564.374</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">Total DSU CAPACITY (SESSIONS)</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2600</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">32.591125</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>
        </div>
        <!-- put some kind of break in here -->
        <div class="search-table-outter wrapper">
        <table class="search-table inner">
 <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th class="border px-4 py-2">Elective</th>
    <th class="border px-4 py-2">Outturn Activity</th>
    <th class="border px-4 py-2">Backlog Activity Demand</th>
    <th class="border px-4 py-2">Additional Activity ECRB (Conversion, say 2% of all specialties)</th>
    <th class="border px-4 py-2">Total Activity</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Required</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Available (52 Weeks)</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Available (5 Days, 50 Weeks)</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Available (5 Days, 48 Weeks)</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Available (5 Days, 46 Weeks)</th>
    <th class="border px-4 py-2">Main Theatres Session Capacity Available (5 Days, 44 Weeks)</th>
    <th class="border px-4 py-2">Capacity Gap/Surplus</th>
    <th class="border px-4 py-2">Anaesthetic Capacity (Sessions Per Week)</th>
    <th class="border px-4 py-2">Anaesthetic Capacity (42 Weeks)</th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2">Anaesthetic Capacity Gap/Surplus</th>
    <th class="border px-4 py-2">Surgical PAs</th>
    <th class="border px-4 py-2">Capacity Gap/Surplus</th>
    <th class="border px-4 py-2">Weekly Patient Volume Required (48 Weeks)</th>
    <th class="border px-4 py-2">Daily Patient Volume (48 Weeks)</th>
    <th class="border px-4 py-2">Weekly Sessions Required</th>
    <th class="border px-4 py-2">Daily Sessions Required</th>
    <th class="border px-4 py-2">Location</th>
    <th class="border px-4 py-2">Commentary</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Cardiac Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Dental</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ENT</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">General Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Maxillofacial</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Neuro Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Paediatric Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Plastic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Thoracic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Trauma & Orthopaedics</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Urology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Gynaecology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Vascular Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">TOTAL</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">9880</td>
      <td class="border" style="padding: 10px;">9120</td>
      <td class="border" style="padding: 10px;">8740</td>
      <td class="border" style="padding: 10px;">8740</td>
      <td class="border" style="padding: 10px;">8360</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">378.352</td>
      <td class="border" style="padding: 10px;">15960</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">15960</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">3 session less theatre option</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">10080</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">8360</td>
      <td class="border" style="padding: 10px;">332.5</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">17640</td>
      <td class="border" style="padding: 10px;">15960</td>
      <td class="border" style="padding: 10px;">1680</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">TOTAL MAIN THEATRE CAPACITY(SESSIONS)</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">11960</td>
      <td class="border" style="padding: 10px;">11500</td>
      <td class="border" style="padding: 10px;">11040</td>
      <td class="border" style="padding: 10px;">10580</td>
      <td class="border" style="padding: 10px;">10120</td>
      <td class="border" style="padding: 10px;">Total Capacity Surplus at 46 Weeks Use</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">380</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
 </table>

        </div>

        <div class="search-table-outter wrapper">
 <table class="search-table inner">
 <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th class="border px-4 py-2">Non elective</th>
    <th class="border px-4 py-2">Outturn Activity</th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2">Contingency due to Ongoing Growth of Non-Elective (2.5%)</th>
    <th class="border px-4 py-2">Theatres Session Capacity Required </th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2">Anaesthetic Capacity (Sessions Per Week)</th>
    <th class="border px-4 py-2">Anaesthetic Capacity (52 Weeks)</th>
    <th class="border px-4 py-2">Anaesthetist Capacity Gap/Surplus</th>
    <th class="border px-4 py-2">Surgical PAs</th>
    <th class="border px-4 py-2">Capacity Gap/Surplus</th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2"></th>
    <th class="border px-4 py-2">Weekly Patient Volume Required (52 Weeks)</th>
    <th class="border px-4 py-2">Daily Patient Volume (52 Weeks, 7 DAYS)</th>
    <th class="border px-4 py-2">Weekly Sessions Required</th>
    <th class="border px-4 py-2">Daily Sessions Required</th>
    <th class="border px-4 py-2">Location</th>
    <th class="border px-4 py-2">Commentary</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Cardiac Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Dental</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ENT</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">General Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Maxillofacial</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Neuro Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Paediatric Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Plastic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Thoracic Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Trauma & Orthopaedics</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Urology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Gynaecology</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Vascular Surgery</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">TOTAL</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">8736</td>
      <td class="border" style="padding: 10px;">8736</td>
      <td class="border" style="padding: 10px;">94.936</td>
      <td class="border" style="padding: 10px;">4936.672</td>
      <td class="border" style="padding: 10px;">4936.672</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">84.6713029852408</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">7280</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">Total Capacity Patients</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">14851.2</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
    </tr>
     </tbody>
    </table>
   </div>
    </div>
    </div>
  </div>   

  <div v-if="stageSummary == 'impatientECR'">
      <div class="route-content">
          <div class="new_new_stat-cards">
             <div class="new_new_stat-cards--item jessica no-hover" style="width:100%; height: 80px;">
              <span class="card-header">Important</span>           
              <span class="card-total3">The table(s) below have a horizontal scroll bar at the bottom due to the amount of data displayed</span>
            </div>

          <div class="search-table-outter wrapper">
            <table class="search-table inner">
              <thead>
  <tr tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="19" class="border px-4 py-2">Specialty |  Admitted backlog clearance </th>
    <th class="border px-4 py-2">Balanced Waiting List (IST)</th>
    <th class="border px-4 py-2">current WL as at 1/4/17</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ENT ELECTIVE</td>
      <td class="border" style="padding: 10px;">09/09/2020</td>
      <td class="border" style="padding: 10px;">16/09/2020</td>
      <td class="border" style="padding: 10px;">23/09/2020</td>
      <td class="border" style="padding: 10px;">30/09/2020</td>
      <td class="border" style="padding: 10px;">07/10/2020</td>
      <td class="border" style="padding: 10px;">14/10/2020</td>
      <td class="border" style="padding: 10px;">21/10/2020</td>
      <td class="border" style="padding: 10px;">28/10/2020</td>
      <td class="border" style="padding: 10px;">04/11/2020</td>
      <td class="border" style="padding: 10px;">11/11/2020</td>
      <td class="border" style="padding: 10px;">18/11/2020</td>
      <td class="border" style="padding: 10px;">25/11/2020</td>
      <td class="border" style="padding: 10px;">02/12/2020</td>
      <td class="border" style="padding: 10px;">09/12/2020</td>
      <td class="border" style="padding: 10px;">16/12/2020</td>
      <td class="border" style="padding: 10px;">23/12/2020</td>
      <td class="border" style="padding: 10px;">30/12/2020</td>
      <td class="border" style="padding: 10px;">06/01/2021</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">DEMAND</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">SLA PLAN</td>
      <td class="border" style="padding: 10px;">Wk 1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ENT IP </td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">l</td>
      <td class="border" style="padding: 10px;">22.2</td>
      <td class="border" style="padding: 10px;">83</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">ENT DC</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">100.8</td>
      <td class="border" style="padding: 10px;">160</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total Regularised Inflow (Referrals)</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">61.290017605627</td>
      <td class="border" style="padding: 10px;">32.76</td>
      <td class="border" style="padding: 10px;">138</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Increased Demand</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">28.2877004333663</td>
      <td class="border" style="padding: 10px;">45.36</td>
      <td class="border" style="padding: 10px;">234</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">BACKLOG CLEARANCE required </td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">19</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total demand </td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">75.6</td>
      <td class="border" style="padding: 10px;">275</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity Target</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">100.297718038993</td>
      <td class="border" style="padding: 10px;">284.28</td>
      <td class="border" style="padding: 10px;">909</td>
   </tr>
  </tbody>
            </table>
          </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
            <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="21" class="border px-4 py-2">CAPACITY </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">CORE CAPACITY IP/DC/LA</td>
      <td class="border" style="padding: 10px;">Wk 1. </td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;">16.8</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;">5.46</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;">7.56</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;">1.26</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;">12.6</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Current Capacity Gap </td>
      <td class="border" style="padding: 10px;">8.69355137232662</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;">-34.9864486276734</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GAP BRIDGING PLAN</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional Internal Capacity</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Increased Weekend Sessions</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Outsourcing to Sulaiman Al Habib</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">4</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Outsourcing to Provider</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;">15</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Outsourcing to Provider</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Outsourcing to Provider</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">23</td>
      <td class="border" style="padding: 10px;">23</td>
      <td class="border" style="padding: 10px;">23</td>
      <td class="border" style="padding: 10px;">28</td>
      <td class="border" style="padding: 10px;">40</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">55</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">55</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">50</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;">55</td>
      <td class="border" style="padding: 10px;">51</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>


          </table>
          </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
            <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="20" class="border px-4 py-2">ACTIVITY </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Complete weekly with actual activity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Activity within core capacity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity (WLI)</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Additional capacity outsourced )</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">A</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">B</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">C</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">D</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Capacity lost </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Cancelled operations </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Annual Leave </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Study Leave </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Sickness </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Cancelled operations </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Under utilisation </td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">91.6041666666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;">135.284166666667</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">% LOST CAPACITY </td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total Activity </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total outsourced</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Specialty Total </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Impact </td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;">-100.297718038993</td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">18 week backlog </td>
      <td class="border" style="padding: 10px;">536</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">284.28</td>
   </tr>
  </tbody>
          </table>
          </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
          <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th class="border px-4 py-2">Consultant PA's </th>
    <th class="border px-4 py-2">weekly clinics</th>
    <th class="border px-4 py-2">lists </th>
    <th class="border px-4 py-2">ops</th>
    <th class="border px-4 py-2">FYE</th>
    <th class="border px-4 py-2">Weekly average (over 52 weeks)  </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.25</td>
      <td class="border" style="padding: 10px;">12</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">60</td>
      <td class="border" style="padding: 10px;">1.2</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">42</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">126</td>
      <td class="border" style="padding: 10px;">2.52</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.25</td>
      <td class="border" style="padding: 10px;">54</td>
      <td class="border" style="padding: 10px;">8</td>
      <td class="border" style="padding: 10px;">186</td>
      <td class="border" style="padding: 10px;">3.72</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">126</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">252</td>
      <td class="border" style="padding: 10px;">5.04</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">84</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">168</td>
      <td class="border" style="padding: 10px;">3.36</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">126</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">252</td>
      <td class="border" style="padding: 10px;">5.04</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">84</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">168</td>
      <td class="border" style="padding: 10px;">3.36</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">10</td>
      <td class="border" style="padding: 10px;">420</td>
      <td class="border" style="padding: 10px;">8</td>
      <td class="border" style="padding: 10px;">840</td>
      <td class="border" style="padding: 10px;">16.8</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2.5</td>
      <td class="border" style="padding: 10px;">105</td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">105</td>
      <td class="border" style="padding: 10px;">2.1</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">42</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">84</td>
      <td class="border" style="padding: 10px;">1.68</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">42</td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">84</td>
      <td class="border" style="padding: 10px;">1.68</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.5</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">1.26</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">189</td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">273</td>
      <td class="border" style="padding: 10px;">5.46</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.5</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">189</td>
      <td class="border" style="padding: 10px;">3.78</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.5</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">126</td>
      <td class="border" style="padding: 10px;">2.52</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1</td>
      <td class="border" style="padding: 10px;">42</td>
      <td class="border" style="padding: 10px;">6</td>
      <td class="border" style="padding: 10px;">252</td>
      <td class="border" style="padding: 10px;">5.04</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.5</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">12</td>
      <td class="border" style="padding: 10px;">378</td>
      <td class="border" style="padding: 10px;">7.56</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.5</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">1.26</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0.5</td>
      <td class="border" style="padding: 10px;">21</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">1.26</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">2</td>
      <td class="border" style="padding: 10px;">84</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">252</td>
      <td class="border" style="padding: 10px;">5.04</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.5</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">189</td>
      <td class="border" style="padding: 10px;">3.78</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1.5</td>
      <td class="border" style="padding: 10px;">63</td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">189</td>
      <td class="border" style="padding: 10px;">3.78</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">210</td>
      <td class="border" style="padding: 10px;">9</td>
      <td class="border" style="padding: 10px;">630</td>
      <td class="border" style="padding: 10px;">12.6</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Total </td>
      <td class="border" style="padding: 10px;">23.25</td>
      <td class="border" style="padding: 10px;">957</td>
      <td class="border" style="padding: 10px;">45</td>
      <td class="border" style="padding: 10px;">2370</td>
      <td class="border" style="padding: 10px;">47.4</td>
   </tr>
  </tbody>
</table>
          </div>
        
          
          </div>        
      </div>   
    </div>

    <div v-if="stageSummary == 'gap'">
      <div class="route-content">
          <div class="new_new_stat-cards">
             <div class="new_new_stat-cards--item jessica no-hover" style="width:100%; height: 80px;">
              <span class="card-header">Important</span>           
              <span class="card-total3">The table(s) below have a horizontal scroll bar at the bottom due to the amount of data displayed</span>
            </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
            <thead>
  <tr  style="background: rgba(229, 233, 242, .5);">
    <th colspan="13" class="border px-4 py-2">Specialty GAP Analysis</th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">1st OPD </td>
      <td class="border" style="padding: 10px;">weekly referrals</td>
      <td class="border" style="padding: 10px;">% of all referrals </td>
      <td class="border" style="padding: 10px;">core capacity </td>
      <td class="border" style="padding: 10px;">capacity gap </td>
      <td class="border" style="padding: 10px;">WL size </td>
      <td class="border" style="padding: 10px;">% of PTL</td>
      <td class="border" style="padding: 10px;">Optimum WL size </td>
      <td class="border" style="padding: 10px;">net WL reduction required </td>
      <td class="border" style="padding: 10px;">contract SLA </td>
      <td class="border" style="padding: 10px;">SLA -50 weeks </td>
      <td class="border" style="padding: 10px;">weekly referrals above SLA </td>
      <td class="border" style="padding: 10px;">current backlog </td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">TOTAL</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">8358.58</td>
      <td class="border" style="padding: 10px;">167.1716</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">235</td>
   </tr>
  </tbody>
          </table>
        </div>
 
  
   
 

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
            <thead>
  <tr  style="background: rgba(229, 233, 242, .5);">
    <th class="border px-4 py-2">Admitted </th>
    <th class="border px-4 py-2">Av weekly additions to WL </th>
    <th class="border px-4 py-2">% of all additions </th>
    <th class="border px-4 py-2">core capacity -ops</th>
    <th class="border px-4 py-2">Capacity Gap </th>
    <th class="border px-4 py-2">WL size</th>
    <th class="border px-4 py-2">% of total WL </th>
    <th class="border px-4 py-2">optimum WL size </th>
    <th class="border px-4 py-2">net WL reduction required </th>
    <th class="border px-4 py-2">contract SLA </th>
    <th class="border px-4 py-2">SLA -50 weeks </th>
    <th class="border px-4 py-2">weekly WL additions  above SLA </th>
    <th class="border px-4 py-2">current backlog </th>
    <th class="border px-4 py-2">backlog / 50 weeks </th>
    <th class="border px-4 py-2">av wait </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">GENERAL ENT </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">HEAD & NECK</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">OTOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">RHINOLOGY </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">VOICE </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">PAEDIATRICS </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">TOTAL</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">2448.15</td>
      <td class="border" style="padding: 10px;">48.963</td>
      <td class="border" style="padding: 10px;">-48.963</td>
      <td class="border" style="padding: 10px;">536</td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;">0</td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">weekly WL reduction </td>
      <td class="border" style="padding: 10px;">0</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">weekly BL reduction </td>
      <td class="border" style="padding: 10px;">10.72</td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
  </tbody>
          
          </table>
          </div>

          <div class="search-table-outter wrapper">
          <table class="search-table inner">
            <thead>
  <tr style="background: rgba(229, 233, 242, .5);">
    <th colspan="4" class="border px-4 py-2">Additional Internal Resources </th>
  </tr>
 </thead>
 <tbody>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Dept.</td>
      <td class="border" style="padding: 10px;">OPD </td>
      <td class="border" style="padding: 10px;">Theatre </td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;"></td>
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">General ENT </td>
      <td class="border" style="padding: 10px;">5</td>
      <td class="border" style="padding: 10px;">1</td>
      
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">Rhinology </td>
      <td class="border" style="padding: 10px;"></td>
      <td class="border" style="padding: 10px;">1</td>
      
   </tr>
    <tr style="height: 0; padding: 10px;">
      <td class="border" style="padding: 10px;">paeds </td>
      <td class="border" style="padding: 10px;">3</td>
      <td class="border" style="padding: 10px;">2</td>
      
   </tr>
  </tbody>
          
          </table>
          </div>

          
     </div>        
      </div>   
    </div>
</div> 
</template>
<script>


export default {
  data() {
    return {
      stageSummary: "ecrinpatient",
      selectedSpeciality: "Acute",
      apiPayload: { speciality: "", apiString: "" },
      activeSummary: "",
      selectedSpecialityArea: "Patient Episodes",
      specialityType: "acute",
      specialityApi: "ahpstwo-collection",
      firstUpdate: false,
      button1: true,
      button2: false,
      button3: false,
      button4: false,
      button5: false, 
    };
  },
  updated() {
   
  },
  beforeMount() {
    console.log("before mounted");
  },
  mounted() {
    console.log("mounted");
    this.activeSummary = "workforceCost";
  },
  watch: {
    selectedSpeciality: {
      handler: function (val) {
        console.log("selectedSpeciality: label: ", val.label);
        console.log("selectedSpeciality: string: ", val.string);
      
        this.editSpecialityType(val.string);
        //console.log("speciality: ", this.specialityApi);
        //this.apiPayload.apiString = this.specialityApi;
        //this.getMongoDBData();
      },
      deep: true,
    },
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    specialityData() {
      return true;
    },  
  },
  methods: {  
    specialityHeader(header) {
      return header;
    },
    editActiveStageSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
    editSpecialityType(specialityTypeName) {

      console.log ("specialityTypeName: ", specialityTypeName);

      if (specialityTypeName == "primary") {
        this.specialityType = "primary";
      }
      if (specialityTypeName == "acute") {
        this.specialityType = "acute";
      }
      if (specialityTypeName == "tertiary") {
        this.specialityType = "tertiary";
      }
      if (specialityTypeName == "mental") {
        this.specialityType = "mental";
      }
      if (specialityTypeName == "virt") {
        this.specialityType = "virt";
      }
      if (specialityTypeName == "long") {
        this.specialityType = "long";
      }

      console.log(
        "editSpecialityType (",
        specialityTypeName,
        ")=",
        this.specialityType
      );
    },
    showButton1() {
      this.button1 = true;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton2() {
      this.button1 = false;
      this.button2 = true;
      this.button3 = false;
      this.button4 = false;
      this.button5 = false;
    },
    showButton3() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = true;
      this.button4 = false;
      this.button5 = false;
    },
    showButton4() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = true;
      this.button5 = false;
    },
    showButton5() {
      this.button1 = false;
      this.button2 = false;
      this.button3 = false;
      this.button4 = false;
      this.button5 = true;
    },
    isEmpty(obj) {
      for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
          return false;
        }
      }

      return JSON.stringify(obj) === JSON.stringify({});
    },
    editStageSummary(stageSummary) {
      this.stageSummary = stageSummary;

      if (this.stageSummary == "buildingsFM") {
        //this.apiPayload.apiString = "fm_build2_collection";
        //this.getMongoDBData();
      }

      if (this.stageSummary == "specialities") {
        console.log(
          " this.selectedSpecialityArea.selected",
          this.selectedSpecialityArea
        );
      }
    },
  },
};
</script>
<style scoped>

.search-table-outter {border:0px; /*solid red; */}
.search-table{table-layout: fixed; margin:40px auto 0px auto; }
.search-table, td, th{border-collapse:collapse; border:1px solid #777;}
th{padding: 1rem; font-size:16px; background: rgba(#E5E9F2, .5); text-align: left; font-weight: 500; line-height: 1;}
td{font-size: 12px; }

.search-table-outter { overflow-x: scroll; }
th, td { min-width: 200px; }
</style>
