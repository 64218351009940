<template>
  <main class="shell">
    <aside class="shell-nav" :class="sidebarOpen ? 'open' : 'closed'">
      <div class="nav-trigger" @click="openSidebar()">
        <jam-menu v-if="!sidebarOpen" />
        <jam-arrow-left v-if="sidebarOpen" />
      </div>
      <nav class="nav-list">
        <ul>
          <li><router-link to="summary"><jam-grid/><span>Stage 1</span></router-link></li>
          <li><router-link to="summary2"><jam-grid/><span>Stage 2</span></router-link></li>
          <li><router-link to="summary3"><jam-grid/><span>Stage 3</span></router-link></li>
          <li><router-link to="summary4_5_6"><jam-grid/><span>Stage 4 - 6</span></router-link></li>
          <li><router-link to="summary7_8_9"><jam-grid/><span>Stage 7 - 9</span></router-link></li>
          <li><router-link to="summary10"><jam-grid/><span>Stage 10</span></router-link></li>
          <li><router-link to="summary11"><jam-grid/><span>Stage 11</span></router-link></li>
          <li><router-link to="businessplan"><jam-grid/><span>Business Plan</span></router-link></li>
          <li><router-link to="operationalimprovementplan"><jam-grid/><span>Operational Improvement Plan</span></router-link></li>
          <li><router-link to="sia"><jam-grid/><span>Strategic Insight Analysis</span></router-link></li>
          <li><router-link to="workforce"><jam-grid/><span>Workforce</span></router-link></li>
          <li><router-link to="financialprojections"><jam-grid/><span>Financial Projections</span></router-link></li>
          <li><router-link to="pricing"><jam-grid/><span>Procedure Pricing</span></router-link></li>
          <li><router-link to="packpricing"><jam-grid/><span>Package Pricing</span></router-link></li>
       </ul>
      </nav>
      <button class="nav-help"><jam-help /></button>
    </aside>
    <div class="shell-container">
      <router-view></router-view>
    </div>
  </main>
</template>

<script>
import { RouterLink } from 'vue-router';

export default {
  data() {
    return {
      sidebarOpen: false,
    };
  },
  methods: {
    openSidebar() {
      this.sidebarOpen = !this.sidebarOpen;
    },
  },
};
</script>

<style lang="scss">
@import "../assets/scss/modules/base.scss";
</style>
