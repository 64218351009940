import Vue from "vue";
import Router from "vue-router";

import loginRoutes from "../modules/login/router";
import summaryRoutes from "../modules/summary/router";
//import specialityRoutes from "../modules/specialities/router";
import speciality3Routes from "../modules/specialities3/router";
import summary2Routes from "../modules/summary2/router";
import summ456Routes from "../modules/summary4_5_6/router";
import summ789Routes from "../modules/summary7_8_9/router";
import summary10Routes from "../modules/summary10/router";
import summary11Routes from "../modules/summary11/router";
import pricingRoutes from "../modules/pricing/router";
import packpricingRoutes from "../modules/packpricing/router";
import benchmarkingRoutes from "../modules/benchmarking/router";
import acoStrategyRoutes from "../modules/aco-strategy/router";
import locationRoutes from "../modules/locations/router";
import toolkitRoutes from "../modules/toolkit/router";
import settingsRoutes from "../modules/settings/router";
import actualLocationRoutes from "../modules/actualLocation/router";
import userRoutes from "../modules/user/router";
import JobPlans from "../modules/jobplans/router";
import Tariff from "../modules/tariff/router";
import summary3Routes from "../modules/summary3/router";
import bpRoutes from '../modules/bpsummary/router';
import StrategicInput from '../modules/strategicinput/router';
import StratwfRoutes from '../modules/stratwfplan/router';
import EcrRoutes from '../modules/ecr/router';
import CIPP from '../modules/cipp/router';
import BusinessPlanRoutes from '../modules/businessplan/router';
import OIP from '../modules/operationalimprovementplan/router';
import SIA from '../modules/sia/router';
import Workforce from '../modules/workforce/router';
import financialprojections from '../modules/financialprojections/router';

Vue.use(Router);

const defaultFallback = {
  path: "/",
  redirect: "/login",
};

const baseRoutes = [defaultFallback];

const routes = baseRoutes.concat(
  financialprojections,
  summ456Routes,
  summ789Routes,
  packpricingRoutes,
  OIP,
  SIA,
  Workforce,
  pricingRoutes,
  BusinessPlanRoutes,
  loginRoutes,
  speciality3Routes,
  summaryRoutes,
  //specialityRoutes,
  summary2Routes,
  summary10Routes,
  summary11Routes,
  benchmarkingRoutes,
  acoStrategyRoutes,
  locationRoutes,
  toolkitRoutes,
  settingsRoutes,
  actualLocationRoutes,
  userRoutes,
  JobPlans,
  Tariff,
  summary3Routes,
  bpRoutes,
  StrategicInput,
  StratwfRoutes,
  EcrRoutes,
  CIPP
);

const router = new Router({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = !!window.localStorage.getItem("token");
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth && !loggedIn)
    next({
      name: "Login",
    });
  next();
});

export default router;
