<template>
  <div>
    <header class="route-header">
      <div class="route-header--left">
        <img src="@/assets/img/hsi_blue-logo-only.png" alt="" />
      </div>
      <div class="route-header--right">
        <h1 class="text-3xl">Job Plans</h1>
      </div>
      <div class="route-header--box">
        <div class="box">
          <!-- img src="@/assets/img/logo-full.svg" alt="" style="width: 55vw; min-width: 330px;" / -->
        </div>
      </div>
    </header>

    <div class="route-header items-center secondary">
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Location</span>
          <span class="box-label">Surrey - United Kingdom</span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Job Plan</span>
          <span class="box-label">
            <v-select
              v-model="selectedSpeciality"
              label="speciality"
              :clearable="false"
              @input="fetchData"
              :options="$store.state.prototypedata.specialities"
            />
          </span>
        </div>
      </div>
      <div class="route-header--box">
        <div class="box">
          <span class="box-header">Total Population</span>
          <span class="box-label">1,199,870</span>
        </div>
      </div>
    </div>

    <div class="route-content">
      <div v-if="jobPlanData != null">
        <!-- 1 open -->
        <table class="table-primary">
          <thead>
            <tr>
              <th class="border px-4 py-2">Consultant Job Plan</th>
              <th class="border px-4 py-2">Patients</th>
              <th class="border px-4 py-2">Per Sessions</th>
              <th class="border px-4 py-2">Annual Sessions</th>
              <th class="border px-4 py-2">Weekly Sessions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="border">
                <div
                  v-for="(jobPlan, index) in jobPlanjobPlanFilter"
                  :key="index"
                >
                <span v-if="jobPlan == ''"><div v-html="message"/></span>
                 <span v-else-if="jobPlan == '-'"><div v-html="message"/></span>
                 <span v-else> {{  jobPlan }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(jobPlanPatients, index) in jobPlanPatientsFilter"
                  :key="index"
                >
                 <span v-if="jobPlanPatients == ''"><div v-html="message"/></span>
                 <span v-else-if="jobPlanPatients == '-'"><div v-html="message"/></span>
                 <span v-else> {{  jobPlanPatients }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(jobPlanperSessions, index) in jobPlanPerSessionFilter"
                  :key="index"
                >
                 <span v-if="jobPlanperSessions == ''"><div v-html="message"/></span>
                 <span v-else-if="jobPlanperSessions == '-'"><div v-html="message"/></span>
                 <span v-else> {{  jobPlanperSessions }}</span>  
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    jobPlanannSessions, index
                  ) in jobPlanPerAnnualSessionFilter"
                  :key="index"
                >
                 <span v-if="jobPlanannSessions == ''"><div v-html="message"/></span>
                 <span v-else-if="jobPlanannSessions == '-'"><div v-html="message"/></span>
                 <span v-else> {{  jobPlanannSessions }}</span> 
                </div>
              </td>

              <td class="border">
                <div
                  v-for="(
                    jobPlanweekSessions, index
                  ) in jobPlanWeeklySessionFilter"
                  :key="index"
                >
                 <span v-if="jobPlanweekSessions == ''"><div v-html="message"/></span>
                 <span v-else-if="jobPlanweekSessions == '-'"><div v-html="message"/></span>
                 <span v-else> {{  jobPlanweekSessions }}</span> 
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div v-if="jobPlanData == null">
        <table class="table-primary">
          <thead>
            <tr>
              <th class="border px-4 py-2">Consultant Job Plan</th>
              <th class="border px-4 py-2">Patients</th>
              <th class="border px-4 py-2">Per Sessions</th>
              <th class="border px-4 py-2">Annual Sessions</th>
              <th class="border px-4 py-2">Weekly Sessions</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              No Data
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      selectedSpeciality: "AHPs",
      indexJob: -1,
      message: "<BR />",
    };
  },
  mounted() {
    //console.log("JobPlans.Vue: begin mounted");
    const speciality = {
      speciality: "AHPs",
      apiString: "ahpsone-collection",
    };
    this.fetchData(speciality);
    //this.selectedSpeciality.speciality = 'AHPs'
    //console.log ("JobPlans.Vue: end mounted")
  },
  computed: {
    store() {
      return this.$store.state.prototypedata;
    },
    specialityName() {
      return this.$store.state.prototypedata.specialityName;
    },
    jobPlanData() {
      if (this.indexJob > 0) {
        return true;
      }
      return null;
    },
    jobPlanjobPlanFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexJob]
          .job_plan;
      }
      return null;
    },
    jobPlanPatientsFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexJob]
          .patients;
      }
      return null;
    },
    jobPlanPerSessionFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexJob]
          .per_session;
      }
      return null;
    },
    jobPlanPerAnnualSessionFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexJob]
          .annual_session;
      }
      return null;
    },
    jobPlanWeeklySessionFilter() {
      if (this.$store.state.prototypedata.specialityData.length > 0) {
        return this.$store.state.prototypedata.specialityData[this.indexJob]
          .weekly_session;
      }
      return null;
    },
  },
  methods: {
    fetchData(speciality) {
      //console.log("JobPlans.Vue: begin fetchData (", speciality);
      this.specialityHeader(speciality.speciality);
      this.$store
        .dispatch("attemptFetchSpecialityData", speciality)
        .then((data) => {
          // TODO: Fix the then ... data issue
          console.log("data", data);
        })
        .finally(function () {
          //console.log ("fetchData: finally");
        });

      //console.log ('speciality ==>: ', speciality);

      //console.log ("length: ", this.$store.state.prototypedata.specialityData.length);

      if (this.$store.state.prototypedata.specialityData.length > 3) {
        //console.log ('here');

        var i = 0;

        for (
          i = 0;
          i < this.$store.state.prototypedata.specialityData.length;
          i++
        ) {
          if ("job_plan" in this.$store.state.prototypedata.specialityData[i]) {
            this.indexJob = i;
          }
        }
      }

      //console.log ('indexJob: ', this.indexJob);
    },
    specialityHeader(header) {
      return header;
    },
    editActiveSummary(activeSummary) {
      this.activeSummary = activeSummary;
    },
  },
};
</script>
